<template>
  <div class="depth-learn">
    <!--顶部-->
    <div class="depth-learn-bread">
      <div class="depth-learn-bread-left">
        <span class="route">深入学习</span>
        <span class="arrow">></span>
        <span class="info"
          >基于“溯源”智能标注 领会最新要点更加精准、高效！</span
        >
      </div>
      <div class="depth-learn-bread-right">
        <span class="refresh">
          <img :src="require('assets/images/refresh11.png')" alt="" />
          <span>刷新</span>
        </span>
      </div>
    </div>
    <!--列表-->
    <div class="depth-learn-list">
      <div class="list-item" v-for="(item, idx) in listArray" :key="idx">
        <div class="list-item-desc" @click="listClick(item)">
          <span :class="['number', pageNum == 1 ? hots[idx] : '']">{{
            idx + 1 + (pageNum - 1) * pageSize
          }}</span>
          <span class="title">{{ item.fileName }}</span>
        </div>
      </div>
      <!--分页-->
      <div class="depth-learn-page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[20, 40, 60, 80]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { depthLearnList } from "api/depthLearn";
import { previewUrl } from "api/common";
export default {
  name: "DepthLearn",
  data() {
    return {
      //列表数据
      listArray: [],
      //页条目数
      pageSize: 20,
      //当前页
      pageNum: 1,
      //总条数
      pageTotal: 0,
      //热度排行
      hots: {
        0: "pink",
        1: "orange",
        2: "blue",
      },
    };
  },
  methods: {
    listClick(val) {
      // console.log(val);
      previewUrl({
        url: this.$store.state.onlineBasePath + val.traceabilityWordPath,
      }).then((res) => {
        window.open(res.data);
      });
    },
    //页条目数改变
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    //当前页改变
    handleCurrentChange(cur) {
      this.pageNum = cur;
      this.getList();
    },
    //获取列表
    async getList() {
      const form = new FormData();
      form.append("page", this.pageNum);
      form.append("limit", this.pageSize);
      this.$store.commit("playLifeLoading", true);
      const res = await depthLearnList(form);
      if (res.code == 200) {
        this.listArray = res.data.data;
        this.pageTotal = res.data.total;
      }
      this.$store.commit("playLifeLoading", false);
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.depth-learn {
  position: relative;
  min-height: 800px;
  padding: 0 20px;
  background-color: #fff;
  &-bread {
    @include flex-between;
    padding: 20px;
    border-bottom: 1px solid #f9f9f9;
    &-left {
      .route {
        @include noselect;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        cursor: pointer;
      }
      .arrow {
        margin: 0 5px;
        font-size: 12px;
        font-weight: 500;
        color: #999999;
      }
      .info {
        font-size: 12px;
        font-weight: 500;
        color: #999999;
      }
    }
    &-right {
      .refresh {
        @include flex-start;
        @include noselect;
        cursor: pointer;
        > span {
          margin-left: 3px;
          font-size: 12px;
          font-weight: 500;
          color: #999999;
        }
      }
    }
  }
  &-list {
    position: relative;
    .list-item {
      @include flex-between;
      padding: 12px 28px 12px 35px;
      border-bottom: 1px solid #f9f9f9;
      &-desc {
        @include flex-start;
        .number {
          width: 30px;
          &.pink {
            color: #f26d5f;
          }
          &.orange {
            color: #ff8200;
          }
          &.blue {
            color: #ff6900;
          }
        }
        .title {
          cursor: pointer;
          @include ellipsis;
          // max-width: 80px;
          width: 1200px;
          margin-top: 2px;
          font-size: 12px;
          font-weight: 400;
          color: #333333;
        }
        .title:hover {
          color: #ff6900;
        }
      }
    }
  }
  &-page {
    @include flex-center;
    padding: 20px 0;
  }
}
.depth-learn-page {
  right: calc(50% - 200px);
}
</style>
